export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        orders :'', 
        order :'', 
    },
    
    getters : {
        orders: (state )=> state.orders,
        order: (state )=> state.order,
    },


    mutations : { 
        setorders :(state,orders ) => (state.orders = orders), 
        setorder :(state,order ) => (state.order = order), 
    },

    actions : {
        async get_orders( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_orders')
            return new Promise((resolve, reject) => {
            axios.post("/global/orders?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_order( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_order' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorder', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_order_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_order_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/global/orders/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setorders', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("order Activated Successfully")
                        } else {
                            toast.success("order Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },


        async process_order( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'process_order' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/process/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorders', response.data.res)
                toast.success("Order In Process")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async complete_order( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'complete_order' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/complete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorders', response.data.res)
                toast.success("Order Completed Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_order( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_order' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/cancel/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorders', response.data.res)
                toast.success("order Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}