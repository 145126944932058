import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import shop  from './modules/shop'
import curriculum_classes  from './modules/curriculum_classes'
import levels  from './modules/levels'
import notifications  from './modules/notifications'
import orders from './modules/orders';

export default createStore({
  modules: {
    auth, 
    utils,
    shop,
    curriculum_classes,
    levels,
    notifications,
    orders,
  },
    
  plugins: [createPersistedState(
    {
      key : 'educan.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
