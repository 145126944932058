import store from '@/store'

const authGuards = (to, from, next) => {
    if(localStorage.getItem('educanToken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id ){
      if (store.getters.authuser.role == 'admin' || store.getters.authuser.role == 'e-commerce') {
        return next()
      } else {
        return next({ name: 'login' })
      }
    }else{
        return next({ name: 'login' })
    }
};


let routes = [{
  path: "/",
  props: true,
  beforeEnter: authGuards,
  component: () => import("@/views/app/main.vue"),
  children: [
    // ============================================== Dashboard ============================================
    {
      path: "/",
      name: 'dashboard',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/dashboard/main.vue"),
      meta: {
        title: 'Dashboard',
      },
    },


    // =================================================== Products ========================================
    {
      path: "/products",
      name: 'products_main',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/products/main.vue"),
      children: [
        {
          path: "",
          name: 'products',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/products/products.vue"),
          meta: {
            title: 'Products',
          },
        },

        {
          path: "add_product",
          name: 'add_product',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/products/add_product.vue"),
          meta: {
            title: 'Add Product',
          },
        },

        {
          path: "update_product/:id",
          name: 'update_product',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/products/update_product.vue"),
          meta: {
            title: 'Update Product',
          },
        },
      ]
    },



    // =================================================== Orders ========================================
    {
      path: "/orders",
      name: 'orders_main',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/orders/main.vue"),
      children: [
        {
          path: "",
          name: 'orders',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/orders/orders.vue"),
          meta: {
            title: 'Orders',
          },
        },

        {
          path: "order_details/:id",
          name: 'order_details',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/orders/details.vue"),
          meta: {
            title: 'Order Details',
          },
        },
      ]
    },


    // ===================================== User Account Settings =================================
    {
      path: "/account",
      name: 'account',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/account/main.vue"),
      children: [
        {
          path: "/profile",
          name: 'profile',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/account/profile.vue"),
          meta: {
            title: 'Profile',
          },
        },
      ]
    },

    {
      path: "/notifications",
      name: '',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/notifications/main.vue"),
      children: [
        {
          path: "",
          name: 'notifications',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/notifications/notifications.vue"),
          meta: {
            title: 'notifications',
          },
        },
      ]
    },

  ]
}]
export default routes;