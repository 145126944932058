export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        shop_categories :'', 
        shop_category :'',
        products :'',
        product :'', 
    },
    
    getters : {
        shop_categories: (state )=> state.shop_categories,
        shop_category: (state )=> state.shop_category,
        products: (state )=> state.products,
        product: (state )=> state.product,
    },


    mutations : { 
        setshop_categories :(state,shop_categories ) => (state.shop_categories = shop_categories), 
        setshop_category :(state,shop_category ) => (state.shop_category = shop_category),
        setproducts :(state,products ) => (state.products = products), 
        setproduct :(state,product ) => (state.product = product), 
    },

    actions : {
        async get_shop_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_shop_categories')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/shop_categories?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshop_categories', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        // ================================= product ===========================================================
        async create_product( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_product')
            return new Promise((resolve, reject) => {
            axios.post("/shop/products/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setproducts', response.data.res)
                toast.success("Product Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_products( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_products')
            return new Promise((resolve, reject) => {
            axios.post("/shop/products?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setproducts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_product( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_product' + id)
            return new Promise((resolve, reject) => {
            axios.post("/shop/products/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setproduct', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_product_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_product_image')
            return new Promise((resolve, reject) => {
            axios.post("/shop/products/image/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setproduct', response.data.res)
                    toast.success('Product Image Updated Successfully')
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_product( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_product' + id)
            return new Promise((resolve, reject) => {
            axios.post("/shop/products/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setproducts', response.data.res)
                toast.success("Product Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_product_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_product_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/shop/products/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setproducts', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Product Activated Successfully")
                        } else {
                            toast.success("Product Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },


        async change_product_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_product_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/shop/products/approval_status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setproducts', response.data.res)
                        if (response.data.res.data[0].approval_status == 1) {
                            toast.success("Product Approved Successfully")
                        } else {
                            toast.success("Product Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_product( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_product' + id)
            return new Promise((resolve, reject) => {
            axios.post("/shop/products/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setproducts', response.data.res)
                toast.success("Product Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}